import React from "react"

const SkillsElemTitle = ({ title }) => (
  <span
    style={{
      textAlign: "center",
    }}
  >
    {title}
  </span>
)

export default SkillsElemTitle
