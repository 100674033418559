import React from "react"
import VP from "../images/experience/VPwhite.png"
import Mautech from "../images/experience/Mautech.png"
import Cleram from "../images/experience/Cleram.png"
import Renault from "../images/experience/Renault.png"
import BNP from "../images/experience/BNP.png"

const experience = [
  {
    image: <BNP />,
    title: "Développeur Full Stack",
    enterprise: "BNP Personnal Finance",
    date: "oct 2023 – mars 2024",
    place: "Levallois, Île-de-France, France",
    tasks: [
      "Evolution de l'outil de crédit à la consommation",
      "Référent Front-end de l'équipe",
      "Montées de versions des environnements React, Node, Typescript"
    ]
  },
  {
    image: <Renault />,
    title: "Développeur Full Stack",
    enterprise: "Renault",
    date: "sept. 2021 – Aujourd’hui",
    place: "Le Plessis-Robinson, Île-de-France, France",
    tasks: [
      "Refonte de l'outil de commande garagiste",
      "Lead développeur React d'une équipe de 3 développeurs",
      "Livraison complète depuis la première mise en production"
    ]
  },
  {
    image: <VP />,
    title: "Développeur Full Stack",
    enterprise: "VP&WHITE",
    date: "févr. 2019 – sept. 2021 ⋅ 2 an 6 mois",
    place: "Boulogne-Billancourt, Île-de-France, France",
    tasks: [
      "Evolutions des intranets / extranets",
      "Développement de dashboard de pilotage consultant",
      "Refonte de l'outil de ticketing",
    ]
  },
  {
    image: <Mautech />,
    title: "Développeur FileMaker",
    enterprise: "Mautech inc.",
    date: "mai 2018 – févr. 2019 ⋅ 10 mois",
    place: "Région de Montréal, Canada",
    tasks: [
      "Refonte d'une solution sous FileMaker Pro",
      "Développement des interfaces graphiques",
      "Développement des fonctionnalités",
    ],
  },
  {
    image: <Cleram />,
    title: "Développeur Front End",
    enterprise: "Cléram",
    date: "févr. 2018 - févr. 2019 ⋅ 1 an 1 mois",
    place: "Région de Paris, France",
    tasks: [
      "Refonte du site internet Wordpress",
      "Synchronisation avec le CRM dynamics 365",
    ],
  },
]

export default experience
