import React from "react"
import SocialIconList from "./SocialIconList"
import MainInfoParagraph from "./MainInfoParagraph"
import MainInfoImage from "./MainInfoImage"

const MainInfo = ({ social, image }) => {
  return (
    <div data-aos="fade-down" className="mainInfo">
      <MainInfoImage data={image} />
      <div className="mainInfo__paragraph">
        <SocialIconList data={social} />
        <MainInfoParagraph />
      </div>
    </div>
  )
}

export default MainInfo
