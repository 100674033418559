import React from "react"
import DropCap from "../elements/dropCap"

const MainInfoParagraph = () => (
  <p
    style={{
      marginTop: "1.45rem",
      fontSize: "1.25rem",
      lineHeight: "2.4rem",
      color: "var(--primary-color)",
    }}
  >
    <DropCap data="S"></DropCap>
    alut à toi jeune entrepreneur ! Mon nom est Jérôme CELEBI.
    <br /> Je suis un développeur Full-Stack basé sur Paris.
    <br />
    <br />
    N'hésitez pas à {" "}
    <a
      style={{ color: "var(--primary-color)" }}
      href="mailto:jerome.celebi@ynov.com"
      alt="votre projet"
    >
      me contacter !
    </a>
  </p>
)

export default MainInfoParagraph
