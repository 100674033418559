import React from "react"
import experience from "../data/experience"

const LinkedinFeed = () => {
  return (
    <div className="linkedinFeed">
      {experience.map(elem => (
        <div key={elem.title} data-aos="fade-down" className="elem">
          <div className="elem__container">
            <div className="elem__image">
              <img alt={elem.title} src={elem.image.type} />
            </div>
          </div>
          <div className="elem__container">
            <div className="elem__title">{elem.title}</div>
            <div className="elem__enterprise">{elem.enterprise}</div>
            <div className="elem__date">{elem.date}</div>
            <div className="elem__place">{elem.place}</div>
            {elem.tasks && (
              <div className="elem__tasks">
                <p>
                  {elem.tasks.map(task => (
                    <>
                      - {task}
                      <br />
                    </>
                  ))}
                </p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LinkedinFeed
