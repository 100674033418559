import React from "react"

const SkillsElemImg = ({ src, title, dark }) => (
  <div
    style={{
      position: "relative",
      maxWidth: "66px",
      width: "100%",
    }}
  >
    <img
      style={{
        width: "100%",
      }}
      className={dark ? "dark" : ""}
      src={src}
      alt={title}
    />
  </div>
)

export default SkillsElemImg
