import React from "react"

const MainInfoImage = ({ data }) => (
  <div style={{ marginTop: `1.45rem` }}>
    <img
      className="light"
      alt={data.alt}
      src={data.image.type}
      style={{ width: `10rem` }}
    />
  </div>
)

export default MainInfoImage
