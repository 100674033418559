import React from "react"
import MainInfo from "../components/mainInfo"
import Experience from "../components/experience"
import Skills from "../components/skills"
import Contact from "../components/contact"
import LinkedinFeed from "../components/linkedinFeed"
import skills from "../data/skills"
import mainImage from "../data/mainImage"
import social from "../data/social"
import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout>
      <MainInfo social={social} image={mainImage} />
      <Experience>
        <LinkedinFeed />
      </Experience>
      <Skills data={skills} />
      <Contact />
    </Layout>
  )
}

export default IndexPage
