import React from "react"
import Git from "../images/icons/github.svg"
import Linkedin from "../images/icons/linkedin.svg"
import Malt from "../images/icons/malt.svg"

const social = [
  {
    title: "malt",
    img: <Malt />,
    href: "https://www.malt.fr/profile/jeromecelebi",
  },
  {
    title: "github",
    img: <Git />,
    href: "https://github.com/WDcontrol",
  },
  {
    title: "linkedin",
    img: <Linkedin />,
    href: "https://www.linkedin.com/in/jerome-celebi/",
  },
]

export default social
