import React from "react"
import SkillsList from "./SkillsList"

const Skills = ({ data }) => {
  return (
    <div
      id="about"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1 style={{ marginBottom: "4em", marginTop: "2em" }}>Skills</h1>
      <SkillsList data={data}></SkillsList>
    </div>
  )
}

export default Skills
