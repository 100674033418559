import React from "react"

const DropCap = ({ data }) => {
  return (
    <span
      style={{
        textTransform: "uppercase",
        display: "inline",
        fontWeight: "300",
        fontSize: "1.8rem",
      }}
    >
      {data}
    </span>
  )
}

export default DropCap
