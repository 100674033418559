import React from "react"
import "./socialIcon.css"

const SocialIcon = ({ data }) => {
  return (
    <a className="socialIcon" href={data.href} rel="noreferrer" target="_blank">
      <img src={data.img.type} alt={data.title} />
    </a>
  )
}

export default SocialIcon
