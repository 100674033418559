import React from "react"

const Experience = ({ children }) => {
  return (
    <div id="experience">
      <h1 style={{ textAlign: "center", marginBottom: "2em" }}>Experience</h1>
      {children}
    </div>
  )
}

export default Experience
