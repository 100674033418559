import React from "react"

const Contact = () => {
  return (
    <h1 id="contact" style={{ marginTop: "2em" }}>
      Contact : jerome.celebi@ynov.com
    </h1>
  )
}

export default Contact
