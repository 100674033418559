import React from "react"

const SkillsLineTitle = ({ title }) => (
  <h4
    style={{
      color: "#7e7e7e",
      transform: "rotate(-90deg) translate3d(-100%,0,0)",
      transformOrigin: "left top",
      position: "absolute",
      left: "0",
      textTransform: "uppercase",
      fontWeight: "300",
      letterSpacing: "2px",
      fontSize: "18px",
    }}
  >
    {title}
  </h4>
)

export default SkillsLineTitle
