import React from "react"
import SkillsElem from "../SkillsElem"
import "./index.css"
import SkillsLineTitle from "../SkillsLineTitle"
const SkillsLine = ({ data }) => {
  const { title, items } = data

  return (
    <div
      data-aos="fade-down"
      style={{
        position: "relative",
        paddingLeft: "52px",
        paddingBottom: "32px",
      }}
    >
      <SkillsLineTitle title={title} />
      <ul className="skillsLine__elem">
        {items.map((elem, index) => (
          <SkillsElem key={index} data={elem} />
        ))}
      </ul>
    </div>
  )
}

export default SkillsLine
