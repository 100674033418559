import React from "react"
import SkillsLine from "../SkillsLine"

const SkillsList = ({ data }) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {data.map((elem, index) => (
        <SkillsLine key={index} data={elem}></SkillsLine>
      ))}
    </div>
  )
}

export default SkillsList
