import React from "react"
import SkillsElemImg from "../SkillsElemImg"
import SkillsElemTitle from "../SkillsElemTitle"

const SkillsElem = ({ data }) => {
  const { title, img, dark } = data
  return (
    <li
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <SkillsElemImg src={img.type} alt={title} dark={dark} />
      <SkillsElemTitle title={title} />
    </li>
  )
}

export default SkillsElem
