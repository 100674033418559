import React from "react"
import Babel from "../images/icons/babel.svg"
import Css3 from "../images/icons/css3.svg"
import Express from "../images/icons/express.svg"
import Javascript from "../images/icons/javascript.svg"
import Html5 from "../images/icons/html5.svg"
import Gatsby from "../images/icons/gatsby.svg"
import Git from "../images/icons/git.svg"
import Graphql from "../images/icons/graphql.svg"
import Mongodb from "../images/icons/mongodb.svg"
import Netlify from "../images/icons/netlify.svg"
import Nodejs from "../images/icons/nodejs.svg"
import Npm from "../images/icons/npm.svg"
import ReactIcon from "../images/icons/react.svg"
import Sass from "../images/icons/sass.svg"
import Webpack from "../images/icons/webpack.svg"
import Wordpress from "../images/icons/wordpress.svg"
import Cssmodules from "../images/icons/cssmodules.png"
import Postman from "../images/icons/postman.svg"
import AspNetCore from "../images/icons/dotnetcore.png"
import Blazor from "../images/icons/blazor.png"
import Typescript from "../images/icons/Typescript.png"
import SpringBoot from "../images/icons/Spring-Boot.png"
import Java from "../images/icons/java.png"
import Next from "../images/icons/nextjs.png"
import Apollo from "../images/icons/Apollo.png"
import Antd from "../images/icons/antd.png"
import StyledComponents from "../images/icons/styledcomponents.png"
import Tailwind from "../images/icons/tailwind.png"
import Python from "../images/icons/python.png"
import AWS from "../images/icons/aws.png"
import Docker from "../images/icons/docker.webp"
import Gitlab from "../images/icons/gitlab.png"

const skills = [
  {
    title: "BASICS",
    items: [
      {
        title: "Javascript",
        img: <Javascript />,
      },
      {
        title: "HTML",
        img: <Html5 />,
      },
      {
        title: "CSS",
        img: <Css3 />,
      },
    ],
  },
  {
    title: "FRONT-END",
    items: [
      {
        title: "ReactJS",
        img: <ReactIcon />,
      },
      {
        title: "Next",
        img: <Next />,
      },
      {
        title: "Antd",
        img: <Antd />,
      },
      {
        title: "SASS",
        img: <Sass />,
      },
      // {
      //   title: "GraphQL",
      //   img: <Graphql />,
      // },
      // {
      //   title: "Apollo",
      //   img: <Apollo />,
      // },
      {
        title: "Styled-Components",
        img: <StyledComponents />,
      },
      {
        title: "Tailwind CSS",
        img: <Tailwind />,
      },
      // {
      //   title: "CSS Modules",
      //   img: <Cssmodules />,
      // },
      // {
      //   title: "GatsbyJS",
      //   img: <Gatsby />,
      // },
      // {
      //   title: "Blazor",
      //   img: <Blazor />,
      // },
    ],
  },
  {
    title: "BACK-END",
    items: [
      {
        title: "NodeJS",
        img: <Nodejs />,
      },
      {
        title: "Python",
        img: <Python />,
      },
      {
        title: "Java",
        img: <Java />,
      }, {
        title: "Spring Boot",
        img: <SpringBoot />,
      },
      {
        title: "ExpressJS",
        img: <Express />,
      },
      {
        title: "MongoDB",
        img: <Mongodb />,
      },
      // {
      //   title: ".Net Core",
      //   img: <AspNetCore />,
      // }
    ],
  },
  {
    title: "TOOLS",
    items: [
      {
        title: "Typescript",
        img: <Typescript />,
      },
      {
        title: "GraphQL",
        img: <Graphql />,
      },
      {
        title: "Postman",
        img: <Postman />,
      },
      {
        title: "Aws",
        img: <AWS />,
      },
      {
        title: "Docker",
        img: <Docker />,
      },
      {
        title: "Gitlab",
        img: <Gitlab />,
      },
      {
        title: "Git",
        img: <Git />,
      },
      {
        title: "Netlify",
        img: <Netlify />,
      },
      {
        title: "NPM",
        img: <Npm />,
      },
      {
        title: "Webpack",
        img: <Webpack />,
      },
      {
        title: "Wordpress",
        img: <Wordpress />,
      },
      {
        title: "Babel",
        img: <Babel />,
      },
    ],
  },
]
export default skills
