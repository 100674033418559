import React from "react"
import SocialIcon from "../elements/socialIcon"

const SocialIconList = ({ data }) => (
  <div>
    {data.map((elem, index) => (
      <SocialIcon key={index} data={elem} />
    ))}
  </div>
)
export default SocialIconList
